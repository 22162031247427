import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Image, Box, Text, Link } from "@theme-ui/components";
import { Signatures, Count } from './signatures';
export const _frontmatter = {
  "title": "Main",
  "slug": "/"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Artists4Bernie is an artist-led campaign. We represent a diverse coalition of people that work within art and culture from around the world, and with this letter, we would like to declare our support for Senator Bernie Sanders and endorse his candidacy for President of the United States. `}<a href="https://forms.gle/D12ZuAnur5dRtY5z5" target="_blank">{`Sign your name`}</a>{` and join us to build a better future.`}</p>
    <Box mt={-4} mdxType="Box" />
    <Box mt={4} mdxType="Box" />
    <Box mt={5} mdxType="Box" />
    <Link href={'https://forms.gle/D12ZuAnur5dRtY5z5'} target="_blank" sx={{
      display: 'block',
      backgroundColor: '#ff0000',
      color: 'black',
      textAlign: 'center',
      fontWeight: 'medium',
      maxWidth: '320px',
      padding: '16px'
    }} mdxType="Link">Sign your name</Link>
    <Box mt={5} sx={{
      borderTop: '1px white solid',
      paddingTop: '32px'
    }} mdxType="Box" />
    <h1>{`Signatures: `}<Count mdxType="Count" /></h1>
    <Box mt={5} mdxType="Box" />
    <Signatures mdxType="Signatures" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      